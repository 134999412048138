import CareersView from '@/views/CareersView.vue'
import ContactView from '@/views/ContactView.vue'
import GalleryView from '@/views/GalleryView.vue'
import HomeView from '@/views/HomeView.vue'
import ServicesView from '@/views/ServicesView.vue'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   component: AboutView
  // },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  },
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/careers',
    name: 'careers',
    component: CareersView
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

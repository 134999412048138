<template>
  <div class="services">
    <div class="full-view bg-gray-light py-16 overflow-hidden">
      <div class="container">
        <h1 class="text-center" data-aos="fade-up">Transportation Services</h1>
      </div>
    </div>
    <div class="my-24">
      <div class="flex flex-wrap mb-12">
        <div
          class="basis-full md:basis-6/12 flex items-center justify-start pr-8"
          data-aos="fade-right"
        >
          <div class="mb-4 md:mb-0">
            <h3 class="text-3xl mb-4 pb-4 divider-bottom-secondary">
              Day Cement
            </h3>
          </div>
        </div>
        <div class="basis-full md:basis-6/12" data-aos="fade-left">
          <img
            class="object-cover h-full w-full max-h-[550px]"
            src="@/assets/images/gallery/2024/portside_truck_white.jpeg"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex flex-wrap mb-12">
        <div
          class="basis-full md:basis-6/12 flex items-center justify-start md:pl-8 md:order-2"
          data-aos="fade-left"
        >
          <div class="mb-4 md:mb-0">
            <h3 class="text-3xl mb-4 pb-4 divider-bottom-primary">
              Night Cement
            </h3>
          </div>
        </div>
        <div class="basis-full md:basis-6/12" data-aos="fade-right">
          <img
            class="object-contain h-full w-full"
            src="@/assets/images/gallery/11_25/IMG_6074.jpg"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex flex-wrap mb-12">
        <div
          class="basis-full md:basis-6/12 flex items-center justify-start pr-8"
          data-aos="fade-right"
        >
          <div class="mb-4 md:mb-0">
            <h3 class="text-3xl mb-4 pb-4 divider-bottom-secondary">
              Cement For Soil Stabilization
            </h3>
          </div>
        </div>
        <div class="basis-full md:basis-6/12" data-aos="fade-left">
          <img
            class="object-contain h-full w-full"
            src="@/assets/images/gallery/11_25/IMG_9796.jpg"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex flex-wrap mb-12">
        <div
          class="basis-full md:basis-6/12 flex items-center justify-start md:pl-8 md:order-2"
          data-aos="fade-left"
        >
          <div class="mb-4 md:mb-0">
            <h3 class="text-3xl mb-4 pb-4 divider-bottom-primary">Fly Ash</h3>
          </div>
        </div>
        <div class="basis-full md:basis-6/12" data-aos="fade-right">
          <img
            class="object-contain h-full w-full"
            src="@/assets/images/gallery/11_25/IMG_3924.jpg"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex flex-wrap mb-12">
        <div
          class="basis-full md:basis-6/12 flex items-center justify-start pr-8"
          data-aos="fade-right"
        >
          <div class="mb-4 md:mb-0">
            <h3 class="text-3xl mb-4 pb-4 divider-bottom-secondary">Sand</h3>
          </div>
        </div>
        <div class="basis-full md:basis-6/12" data-aos="fade-left">
          <img
            class="object-contain h-full w-full"
            src="@/assets/images/gallery/2024/Resized_20240910_111440_1725981290325.jpeg"
            loading="lazy"
          />
        </div>
      </div>
      <div class="flex flex-wrap mb-12">
        <div
          class="basis-full md:basis-6/12 flex items-center justify-start md:pl-8 md:order-2"
          data-aos="fade-left"
        >
          <div class="mb-4 md:mb-0">
            <h3 class="text-3xl mb-4 pb-4 divider-bottom-primary">Rock</h3>
          </div>
        </div>
        <div class="basis-full md:basis-6/12" data-aos="fade-right">
          <img
            class="object-cover h-full w-full max-h-[550px]"
            src="@/assets/images/gallery/2024/IMG_1163.jpeg"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<template>
  <div class="images py-24">
    <div class="flex flex-wrap mb-12">
      <div
        class="basis-full md:basis-6/12 flex items-center justify-start pr-8 mb-4 md:mb-0"
        data-aos="fade-right"
      >
        <div>
          <h3 class="text-3xl pb-4 mb-4 divider-bottom-secondary">
            Proven Satisfaction
          </h3>
        </div>
      </div>
      <div class="basis-full md:basis-6/12" data-aos="fade-left">
        <img
          class="max-h-[550px] object-cover w-full h-full"
          src="@/assets/images/gallery/2024/IMG_0798.jpeg"
          loading="lazy"
        />
      </div>
    </div>
    <div class="flex flex-wrap mb-12">
      <div
        class="basis-full md:basis-6/12 flex items-center justify-start md:order-2 md:pl-8 mb-4 md:mb-0"
        data-aos="fade-left"
      >
        <div>
          <h3 class="text-3xl pb-4 mb-4 divider-bottom-primary">
            Committed To You
          </h3>
        </div>
      </div>
      <div class="basis-full md:basis-6/12" data-aos="fade-right">
        <img
          class="max-h-[550px] object-cover w-full h-full"
          src="@/assets/images/gallery/2024/IMG_1130.jpeg"
          loading="lazy"
        />
      </div>
    </div>
    <div class="flex flex-wrap mb-12">
      <div
        class="basis-full md:basis-6/12 flex items-center justify-start pr-8 mb-4 md:mb-0"
        data-aos="fade-right"
      >
        <div>
          <h3 class="text-3xl pb-4 mb-4 divider-bottom-secondary">
            On-Time Delivery
          </h3>
        </div>
      </div>
      <div class="basis-full md:basis-6/12" data-aos="fade-left">
        <img src="@/assets/images/gallery/11_25/IMG_3924.jpg" loading="lazy" />
      </div>
    </div>
  </div>
</template>

<template>
  <section class="hero row-full">
    <div class="hero-container relative h-[50vh] min-h-[550px]">
      <img
        class="hero-image absolute cover brightness-40"
        loading="lazy"
        src="@/assets/images/IMG_4204_banner.jpg"
        alt="Portside Transport LLC"
      />
      <div class="hero-content-wrapper relative p-8">
        <div class="hero-content center center-position container">
          <img
            class="hero-logo mx-auto max-h-[250px] h-full w-auto"
            loading="lazy"
            src="@/assets/images/logo.png"
            alt="Portside Transport LLC"
          />
          <p class="tagline" style="margin-top: 0">
            Bulk Transportation for the Southeast
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<template>
  <div class="services-wrapper bg-neutral-200 full-view py-24">
    <div class="container px-5">
      <div
        class="flex flex-col flex-wrap gap-y-8 max-w-3xl mx-auto md:flex-row md:justify-between"
      >
        <div class="flex-grow basis-1/2" data-aos="fade-right">
          <h2 class="pb-4 mb-4 divider-bottom-secondary">
            Bulk Tanker Services
          </h2>
          <ul class="pl-0 list-none">
            <li class="mb-2">
              <p class="text-lg">Cement</p>
            </li>
            <li class="mb-2">
              <p class="text-lg">Fly Ash</p>
            </li>
            <li class="mb-2">
              <p class="text-lg">Sand</p>
            </li>
            <li class="mb-2">
              <p class="text-lg">Lime</p>
            </li>
            <li class="">
              <p class="text-lg">Calcium Carbonate</p>
            </li>
          </ul>
        </div>
        <div class="flex-grow basis-1/2" data-aos="fade-left">
          <h2 class="pb-4 mb-4 divider-bottom-primary">End-Dump Services</h2>
          <ul class="pl-0 list-none">
            <li class="mb-2">
              <p class="text-lg">Sand</p>
            </li>
            <li class="mb-2">
              <p class="text-lg">Stone</p>
            </li>
            <li class="mb-2">
              <p class="text-lg">Rock</p>
            </li>
            <li class="mb-2">
              <p class="text-lg">Lime</p>
            </li>
            <li class="">
              <p class="text-lg">Fertilizer</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
